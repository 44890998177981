import { BusinessLocation } from '../../types';

declare const STATIC_URL: string;

type LocationType = 'dispensary' | 'grower';

interface Options {
    inputEl: HTMLInputElement;
    locationType: LocationType;
    onArrowUp?: (
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) => void;
    onArrowDown?: (
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) => void;
    onChange: (payload?: { url: string; id: number }) => void;
}

export interface PayloadItem extends BusinessLocation {
    url: string;
    id: number;
    business_location_id: number;
}

class LocationLookupWidget {
    inputEl;
    onArrowDown;
    onArrowUp;
    onChange;
    currentValue: Partial<PayloadItem> | undefined;

    constructor(options: Options) {
        this.inputEl = options.inputEl;
        this.onArrowUp = options.onArrowUp;
        this.onArrowDown = options.onArrowDown;
        this.onChange = options.onChange;

        this.registerInputHandler(options.locationType);
    }

    initEventHandlers() {
        const payloadItems = this.inputEl.parentElement?.querySelectorAll<HTMLElement>(
            '.payloads-region .search-payload'
        );
        if (!payloadItems) return;

        for (const payloadItem of payloadItems) {
            payloadItem.addEventListener('click', () => {
                const text = payloadItem.dataset.name,
                    url = payloadItem.dataset.url,
                    id = payloadItem.dataset.id;

                if (url && typeof text === 'string' && id) {
                    this.onSelect(url, text, parseInt(id));
                }
            });
        }
    }

    registerInputHandler(type: LocationType) {
        $(this.inputEl).on('keyup', (e) => {
            const text = this.inputEl.value,
                $payloadsRegion = $(this.inputEl)
                    .parent()
                    .find('.payloads-region'),
                $activePayload = $payloadsRegion.find('.search-payload.active'),
                url = $activePayload[0]?.dataset.url,
                id = $activePayload[0]?.dataset.id,
                $firstPayload = $payloadsRegion.find(
                    '.search-payload:nth-child(1)'
                ),
                $lastPayload = $payloadsRegion.find(
                    '.search-payload:last-child'
                );

            if (e.code === 'ArrowUp' || e.key === 'ArrowUp') {
                // Up Arrow Key
                this.onArrowUpEvent(
                    e,
                    $activePayload,
                    $firstPayload,
                    $lastPayload
                );
                return;
            }

            if (e.code === 'ArrowDown' || e.key === 'ArrowDown') {
                // Down Arrow Key
                this.onArrowDownEvent(
                    e,
                    $activePayload,
                    $firstPayload,
                    $lastPayload
                );
                return;
            }

            if (e.code === 'Enter' || e.key === 'Enter') {
                // Enter Key
                if (url && typeof text === 'string' && id) {
                    this.onSelect(url, text, parseInt(id));
                }
                return;
            }

            if (typeof text === 'string' && text.length >= 1) {
                let baseUrl;

                if (type === 'dispensary') {
                    baseUrl = '/api/v1/search/dispensary/lookup/';
                } else if (type === 'grower') {
                    baseUrl = '/api/v1/search/grow_house/lookup/';
                }

                const url = `${baseUrl}?q=${text}`;

                $.ajax({
                    method: 'GET',
                    url,
                    success: (data) => {
                        $payloadsRegion.html('');
                        const html = this.buildPayloadLookupHtml(data, type);
                        if (html) {
                            $payloadsRegion.append(html);
                            $payloadsRegion.show();
                            this.initEventHandlers();
                        } else {
                            $payloadsRegion.hide();
                        }
                    },
                });
            } else {
                $payloadsRegion.html('');
                $payloadsRegion.hide();
            }

            this.onChange();
        });
    }

    onArrowUpEvent(
        e: JQuery.KeyUpEvent,
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) {
        e.preventDefault();

        if (this.onArrowUp) {
            this.onArrowUp($activePayload, $firstPayload, $lastPayload);
            return;
        }

        if ($activePayload && $activePayload.length > 0) {
            if ($activePayload.prev().length > 0) {
                $activePayload.removeClass('active');
                $activePayload.prev().addClass('active');
            }
        } else {
            $lastPayload.addClass('active');
        }
    }

    onArrowDownEvent(
        e: JQuery.KeyUpEvent,
        $activePayload: JQuery,
        $firstPayload: JQuery,
        $lastPayload: JQuery
    ) {
        e.preventDefault();

        if (this.onArrowDown) {
            this.onArrowDown($activePayload, $firstPayload, $lastPayload);
            return;
        }

        if ($activePayload && $activePayload.length > 0) {
            if ($activePayload.next().length > 0) {
                $activePayload.removeClass('active');
                $activePayload.next().addClass('active');
            }
        } else {
            $firstPayload.addClass('active');
        }
    }

    onSelect(url: string, text: string, id: number) {
        if (url && text && id) {
            const $payloadsRegion = $('.payloads-region');

            this.inputEl.dataset.url = url;
            this.inputEl.dataset.id = id.toString();
            this.inputEl.value = text;
            $payloadsRegion.html('');
            $payloadsRegion.hide();

            this.onChange({ url, id });
        }
    }

    buildPayloadLookupHtml(
        data: { total: number; payloads: PayloadItem[] },
        type: LocationType
    ) {
        return data.payloads
            .map((payload) => {
                const url =
                    type === 'dispensary'
                        ? payload.urls.dispensary
                        : // FIX: We don't get urls for most growers
                          payload.urls.grow_house || payload.url;
                const loc =
                    payload.city && payload.state
                        ? ` (${payload.city}, ${payload.state}) `
                        : '';
                const id = payload.business_location_id;
                const img_src =
                    payload.image ||
                    `${STATIC_URL}images/default-location-image.jpeg`;
                const name = payload.location_name;
                const dist = payload.distance
                    ? `${Math.round(payload.distance * 10) / 10} Miles`
                    : '';

                const html = `
                    <span class="search-payload" data-id="${id}" data-url="${url}" data-name="${name}">
                        <img width="20" height="20" src="${img_src}">
                        <span class="disp-name">${name}</span>
                        <span>${loc}${dist}</span>
                    </span>
                    `;

                return html;
            })
            .join('');
    }
}

export default LocationLookupWidget;
