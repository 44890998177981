interface EventData {
    event: 'DISP_CALL' | 'DISP_GETDIR' | 'DISP_LOOKUP';
    entity_id: number;
}

export default function track(event_data: EventData) {
    $.ajax({
        method: 'POST',
        data: JSON.stringify(event_data),
        url: '/api/v1/analytics/track',
        success: function (data) {
            console.log(data);
        },
    });
}
