import { UserLocation } from '../types';

export default function formatAddress(location: Partial<UserLocation>): string {
    if (!location) return '';

    const l = Object.keys(location)
        .filter(
            (key) =>
                key === 'street1' ||
                key === 'street2' ||
                key === 'city' ||
                key === 'state' ||
                key === 'zipcode'
        )
        .map((key) => location[key as keyof UserLocation])
        .filter((val) => val);

    if (l.length === 0 && typeof location.location_raw === 'string') {
        const parsed = JSON.parse(location.location_raw);
        if (parsed && parsed[0] && parsed[0].formatted_address) {
            return (l + parsed[0].formatAddress).join(', ');
        }
    }

    return l.join(', ');
}
