import Cookies from 'js-cookie';
import formatAddress from '../common/formatAddress';
import GoogleLocations from '../common/GoogleLocations';
import {
    getAddressFromPlace,
    getLocation,
    getTimezone,
} from '../common/locationUtils';
import { PageOptions, UserLocation } from '../types';

class LocationView {
    ui: Record<string, JQuery>;
    authenticated;
    userId;
    location;
    timezone?: string;

    constructor(options: PageOptions) {
        this.ui = {
            locationField: $('.your-location-value'),
            $locationForm: $('#location-form'),
        };
        this.authenticated = options && options.authenticated;
        this.userId = options && options.userId;
        this.location = options && options.location;

        if (!this.location) {
            this.getUserLocation();
        }

        this.preFillUserLocation();
        this.onChangeLocation();

        this.ui.$locationForm.on('submit', () => false);
    }

    updateTimezone(address: UserLocation, success: () => void) {
        if (typeof address !== 'undefined' && address.lat && address.lng) {
            getTimezone(
                address.lat,
                address.lng,
                ({ timeZoneId }: { timeZoneId: string }) => {
                    this.timezone = timeZoneId;
                    success();
                }
            );
        }
    }

    onChangeLocation() {
        const that = this,
            googleLocations = new GoogleLocations({
                inputEl: $<HTMLInputElement>('#location')[0],
            });

        googleLocations.initGoogleAutocomplete(
            function (autocomplete, inputEl) {
                const $el = $(inputEl),
                    address = googleLocations.getAddressFromAutocomplete();

                if (address) {
                    $el.val(formatAddress(address));
                    $el.trigger('blur');
                    that.updateTimezone(address, function () {
                        that.saveUserLocation(address);
                    });
                }
            },
            function (results, status, inputEl) {
                if (!results || status !== 'OK') return;
                const $el = $(inputEl),
                    address = getAddressFromPlace(results[0]);

                $el.val(formatAddress(address));
                $el.trigger('blur');

                that.updateTimezone(address, function () {
                    that.saveUserLocation(address);
                });
            },
            function (inputEl) {
                const $removeBtn = $('.remove-location');
                $('.check').hide();
                $removeBtn.removeClass('hidden');
                $removeBtn.on('click', () => {
                    if (!that.authenticated) {
                        Cookies.remove('user_geo_location');
                    }

                    $removeBtn.addClass('hidden');
                    $('.check').show();
                    $(inputEl).val('');
                });
            }
        );
    }

    preFillUserLocation() {
        if (this.location) {
            this.ui.locationField
                .val(formatAddress(this.location))
                .trigger('change');
        }
    }

    getUserLocation() {
        getLocation(
            ({ address, timezone }) => {
                this.timezone = timezone;
                this.saveUserLocation(address);
                this.location = address;
                if (!this.ui.locationField.val()) {
                    this.preFillUserLocation();
                }
            },
            () => {
                console.log('Cannot locate user');
            }
        );
    }

    saveUserLocation(data: UserLocation) {
        const locationRaw = data.location_raw;

        if (locationRaw) {
            const parsed = JSON.parse(locationRaw);
            if (parsed && parsed[0] && this.ui.locationField.length) {
                this.ui.locationField
                    .val(parsed[0].formatted_address)
                    .trigger('change');
            }
        }

        if (this.authenticated) {
            $.ajax({
                method: 'POST',
                url: `/api/v1/users/${this.userId}/geo_locations`,
                data: JSON.stringify({
                    address: data,
                    timezone: this.timezone || '',
                }),
            });
        } else {
            delete data.location_raw;
            Cookies.set('user_geo_location', JSON.stringify(data));
        }
    }
}

export default LocationView;
