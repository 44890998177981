import loader from './googleLoader';
import { getAddressFromPlace } from './locationUtils';

interface Options {
    inputEl: HTMLInputElement;
    pacContainerIndex?: number;
}

type OnEnterKey = (
    results: google.maps.GeocoderResult[] | null,
    status: google.maps.GeocoderStatus,
    inputEl: HTMLInputElement
) => void;

class GoogleLocations {
    inputEl;
    autocomplete?: google.maps.places.Autocomplete;
    pacContainerIndex;
    onEnterKey?: OnEnterKey;

    constructor(options: Options) {
        if (!options || !options.inputEl) {
            console.error('inputEl is required to use location autocomplete');
        }

        this.inputEl = options.inputEl;
        loader.load().then(() => {
            this.autocomplete = new google.maps.places.Autocomplete(
                this.inputEl
            );
        });
        this.pacContainerIndex = options && options.pacContainerIndex;
    }

    initGoogleAutocomplete(
        onPlaceChange: (
            autocomplete: google.maps.places.Autocomplete,
            inputEl: HTMLInputElement
        ) => void,
        onEnterKey: OnEnterKey,
        onLocationRemove: (input: HTMLInputElement) => void
    ) {
        const that = this;

        function onLocationRemoveFunction() {
            if (that.inputEl.value && onLocationRemove) {
                onLocationRemove(that.inputEl);
            }
        }

        this.onEnterKey = onEnterKey;

        onLocationRemoveFunction();

        loader.load().then(() => {
            if (!that.autocomplete) return;
            google.maps.event.addListener(
                that.autocomplete,
                'place_changed',
                function () {
                    const place = that.autocomplete?.getPlace();
                    if (!place || !place.address_components) {
                        let firstResult;
                        const geoCoder = new google.maps.Geocoder();

                        if (
                            that.pacContainerIndex &&
                            that.pacContainerIndex >= 0
                        ) {
                            const $pacs = $('.pac-container');
                            firstResult = $($pacs[that.pacContainerIndex])
                                .find('.pac-item:first')
                                .text();
                        } else {
                            firstResult = $(
                                '.pac-container .pac-item:first'
                            ).text();
                        }
                        geoCoder.geocode(
                            { address: firstResult },
                            function (results, status) {
                                if (that.onEnterKey) {
                                    that.onEnterKey(
                                        results,
                                        status,
                                        that.inputEl
                                    );
                                }
                            }
                        );
                    } else if (that.autocomplete) {
                        onPlaceChange(that.autocomplete, that.inputEl);
                    }

                    onLocationRemoveFunction();
                }
            );
        });

        $(this.inputEl).on('change paste keyup', onLocationRemoveFunction);
    }

    getAddressFromAutocomplete() {
        const that = this,
            place = this.autocomplete?.getPlace();

        if (place && place.address_components) {
            return getAddressFromPlace(place);
        } else {
            let firstResult;
            const geoCoder = new google.maps.Geocoder();

            if (this.pacContainerIndex && this.pacContainerIndex >= 0) {
                const $pacs = $('.pac-container');
                firstResult = $($pacs[this.pacContainerIndex])
                    .find('.pac-item:first')
                    .text();
            } else {
                firstResult = $('.pac-container .pac-item:first').text();
            }

            geoCoder.geocode(
                { address: firstResult },
                function (results, status) {
                    if (status !== 'OK') {
                        alert('Invalid address');
                    } else {
                        if (that.onEnterKey) {
                            that.onEnterKey(results, status, that.inputEl);
                        }
                    }
                }
            );
        }
    }
}

export default GoogleLocations;
